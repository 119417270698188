import React, { ReactElement } from 'react';

import { ROUTES } from '../../other/config';
import { TSearchItem, TSearchResult } from '../../types/search';


function getRoute(category, id) {
	switch (category) {
		case 'VESSEL':
			return `${ROUTES.VESSEL_EDIT}/${id}`;
		case 'COMPANY':
			return `${ROUTES.COMPANY_EDIT}/${id}`;
		case 'PERSON':
			return `${ROUTES.PEOPLE_EDIT}/${id}`;
		default:
			return ROUTES.HOME;
	}
}


export function getSearchOptions(arr: ReadonlyArray<TSearchResult>, query: string): {}[] {
  if (arr && !arr.length) return getEmptyOption(query);

  const getGroup = ({ type, items }: TSearchResult): {} => {
    const getItem = ({ id, text }: TSearchItem) => {
      return {
        value: getRoute(type, id),
        label: (
          <div className="Search__item">{text}</div>
        )
      };
    };

    return {
      label: renderTitle(type),
      options: items.map(getItem)
    };
  };

  return arr && arr.map(getGroup);
}


function renderTitle(title: string): ReactElement {
  return (
    <span className='Search__group--title' key={Math.random()}>
      {title}
    </span>
  );
}


function getEmptyOption(query: string): {}[] {
  if (!query) return null;

  const label = (
    <div className='Search__empty'>
      No matches for <b>{query}</b>
    </div>
  );
  return [{
    label,
    options: []
  }];
}
