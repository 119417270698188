import { createAsyncActions } from '../_utils/createAsyncActions';
import { TPrefetchState } from './prefetch';

export enum EPrefetchActions {
	FETCH_DICTIONARIES = 'dictionaries/FETCH_DICTIONARIES',
	ASSIGN_COMPANIES = 'dictionaries/ASSIGN_COMPANIES',
	INSERT_COMPANY = 'dictionaries/INSERT_COMPANY'
}

export const fetchActs = createAsyncActions<TPrefetchState>(EPrefetchActions.FETCH_DICTIONARIES);
