import { basicInitialState } from '../_utils/basicInitialState';
import { TVesselState } from './vesselModel';

export const vesselInitialState: TVesselState = {
  ...basicInitialState,
  fields: {},
  history: [],
  isTouched: false,
  photos: [],
  vessel: null
};
