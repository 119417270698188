import packageJson from '../../package.json';

export const BASE_MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
export const CODEBASE_NAME = packageJson.name;
export const VERSION = packageJson.version;

export const ROUTES = {
  COMPANIES: '/companies',
  COMPANY: '/company',
  COMPANY_EDIT: '/company/edit',
  DEVELOPER: '/developer',
  FARMS: '/farms',
  FARM_EDIT: '/farm/edit',
  FARM_LICENCES: '/farm/licences',
  HOME: '/',
  LICENCE_EDITOR: '/farm/licence/edit',
  LOGIN: '/login',
  PEOPLE: '/people',
  PEOPLE_EDIT: '/people/edit',
  TEST: '/test',
  USERS: '/users',
  USER_EDIT: '/user/edit',
  USER_GROUPS: '/user-groups',
  USER_GROUP_EDIT: '/user-group/edit',
  VESSEL: '/vessel',
  VESSELS: '/vessels',
  VESSEL_EDIT: '/vessel/edit'
};

export const ENDPOINTS = {
  COMPANIES: '/companies',
  DICTIONARIES: '/dictionaries',
  FARMS: '/fishfarms',
  FARM_LICENCES: '/fishfarms/licences',
  FAVORITE_VESSELS: '/fleet/vessels',
  FLEET: '/fleet',
  LOGIN: '/login',
  LOGOUT: '/logout',
  NOTES: '/notes',
  NOTES_VESSEL: '/notes/vessel',
  NOTIFICATIONS: '/notifications',
  PEOPLE: '/persons',
  REPORTS: '/companies',
  SEARCH: '/search',
  SERVICE_PROVIDER: '/serviceProvider',
  SESSION: '/user/active',
  USER_GROUPS: '/user-groups',
  USER_ROLES: '/user/roles',
  USER_USERS: '/user/users',
  USERS: '/users',
  VESSEL: '/vessel',
  VESSELS: '/vessels',
};

export const SUFFIXES = {
  HISTORY: '/history',
  NOTIFICATION: '/notification',
  PHOTOS: 'photos',
  RECALCULATION: '/run-vessels-locations-alerting',
  REPORTS: 'annualreports',
  USER: 'user'
};

export const ASIDE_WIDTH = 200;
export const BREAKPOINT = {
  BP1368: 1368,
  BP1280: 1280,
  BP1024: 1024,
  BP992: 992,
  BP768: 768,
  BP576: 576
};

export const DATE_FORMAT = 'YYYY-MM-DD';
export const LOCALE = 'en_GB';

export const REGEXP_INTEGER = /^\d+$/;
export const REGEXP_DATE = /^\d{4}-\d{2}-\d{2}$/;
export const REGEXP_POSITIVE_NUMBER = /^\d+(\.?\d{1,4})?$/;
export const REGEXP_DECIMAL = /^(\d+\.?\d{1,7}|\.\d{1,4})$/;
