import { companiesInsert } from '../store/companies/companiesActions';
import { insertCompany } from '../store/dictionaries/prefetchActions';
import store from 'store/store';
import { ECompaniesActions } from '../store/companies/companiesConstants';

/**/
enum EBroadcastMessageTarget {
  STORE = 'STORE'
}

type Message<P, T extends string> = {
  target: EBroadcastMessageTarget.STORE;
  type: T;
  payload: P;
};

type TBroadcastMessage = Message<any, any>;


/**/
/**/
class MessagingService {
  static MESSAGING_KEY = 'MESSAGING';

  /**/
  constructor() {
    window.addEventListener('storage', (event) =>
      event.storageArea === localStorage &&
        event.key === MessagingService.MESSAGING_KEY &&
        MessagingService.handleMessage(event)
    );

    window.addEventListener('beforeunload', () =>
      window.localStorage.removeItem(MessagingService.MESSAGING_KEY)
    );
  }

  /**/
  public static handleMessage(event: StorageEvent): void {
    if (!event.newValue) return;

    try {
      const msg = JSON.parse(event.newValue) as TBroadcastMessage;
      switch (msg.target) {
        case EBroadcastMessageTarget.STORE:
          return MessagingService.handleStoreMessage(msg);
        default:
          MessagingService.reportWarning(msg);
      }

    } catch (e) {
      window.console.error(e);
    }
  }

  /**/
  public static handleStoreMessage(message: TBroadcastMessage): void {
    switch (message.type) {
      case ECompaniesActions.INSERT_COMPANY:
        store.dispatch(companiesInsert(message.payload));
        store.dispatch(insertCompany(message.payload));
        break;
      default:
        MessagingService.reportWarning(message);
    }
  }

  /**/
  public static postMessage<T extends string>(
    payload: any,
    type: T,
    target = EBroadcastMessageTarget.STORE
  ): void {
    let msg;
    try {
      msg = JSON.stringify({ payload, type, target });
    } catch (e) {
      window.console.error(e);
    }

    window.localStorage.setItem(MessagingService.MESSAGING_KEY, msg);
  }

  /**/
  public static reportWarning(message: TBroadcastMessage): void {
    window.console.warn('MessagingService:: unhandled message:', message);
  }
}

/**/
export default MessagingService;
