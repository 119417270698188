import React, { ReactElement } from 'react';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import dayjs, { Dayjs } from 'dayjs';

import { DATE_FORMAT } from '../config';
import { extractFieldsErrors, showFieldsErrors } from './errorMessage';

import { TCountry } from '../../types/dictionaries';
import { TFormField, TFormFields, TValidationRule } from '../../types/forms';
import { TLabelValue } from '../../types/other';


export const formItemProps = {
  colon: false,
  validateTrigger: 'onBlur'
};
export const commonFormProps = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
  hideRequiredMark: true
};

export type TItemProps = {
  label?: string;
  name: string;
  placeholder?: string;
  rows?: number;
  rules?: TValidationRule[];
  validateTrigger?: string; // actually 'onBlur' | 'onChange';
};

/** Converts an array of antd4's form fields into a dictionary */
export function formFields2Dict(fields: TFormField[]): TFormFields {
  const dict = {};

  fields.forEach((_field: TFormField) => {
    if (_field.name.length > 1) return;
    dict[_field.name[0]] = _field;
  });
  return dict;
}

/** Plucks given fields from the object and converts them into antd's form fields */
export function buildFields(fieldNames: string[], obj: {} = {}): TFormFields {
  const fields = {};
  fieldNames.forEach((field: string) => {
    fields[field] = {
      name: [field],
      value: obj[field]
    };
  });

  return fields;
}

/** Builds a form field. */
export function buildFields2(arr: Array<{
  [fieldName: string]: number | string | boolean | Dayjs | Dayjs[] | void | null
}>): TFormFields {
  const fields = {};
  arr.forEach((obj) => {
    const [fieldName, value] = Object.entries(obj)[0];
    fields[fieldName] = {
      name: [fieldName],
      value: value === void 0 || value === null
        ? ''
        : value
    };
  });

  return fields;
}

/** Builds form field which value is a uniform array */
export function buildArrayField(fieldName: string, values: Array<{}>): TFormFields {
  return {
    [fieldName]: {
      name: [fieldName],
      value: (values || []) as any
    }
  };
}

/** Converts antd form fields into a dictionary */
export function pluckFromFields(fields: TFormFields): {
  [key: string]: any
} {
  const result = {};
  Object.values(fields).forEach(({ name, value }: TFormField) =>
    result[name[0]] = value
  );
  return result;
}

// Ignores our locale time shift, as if we were on UTC time zone.
export function ignoreTimeShift(date: Dayjs): Dayjs {
  if (!dayjs.isDayjs(date)) return date;

  return date
    .clone()
    .add(date.utcOffset(), 'minutes');
}

/**
 * Converts string date representation into Dayjs instance. Disregards time.
 */
export function stringDate2moment(str: string): Dayjs | void {
  if (!str) return;
  return dayjs(str.substring(0, 10));
}

/** Converts moment date into short date string of YYYY-MM-DD */
export function moment2DateStr(date: Dayjs): string {
  if (!dayjs.isDayjs(date)) return date;
  return date.toISOString().substring(0, 10);
}

/**
 * Used as a type-and-search filter callback for antd's Select.
 */
export function typeSearchFilter(input: string, option: TLabelValue): boolean {
  const index = option.label
    .toLowerCase()
    .indexOf(input.toLowerCase());
  return index >= 0;
}

export function itemProps2formItems(props): ReactElement {
  return (
    <Form.Item key={props.name} {...props} >
      <Input />
    </Form.Item>
  );
}


export function getMomentDateAsUTC(dateString: string): Dayjs | void {
  if (!dateString) return void 0;
  return dayjs(
    dateString + 'T00:00:00',
    DATE_FORMAT
  );
}


export function findCountryCodeByName(country: string, countries: TCountry[]): string {
  const countryObj = countries.find(({ label }: TCountry) => label === country);
  return countryObj ? countryObj.value : '';
}

export function findCountryByISO(iso: string, countries: TCountry[]): string {
  if (!iso) return '';

  return countries.find(({ value }: TCountry) =>
    value === iso
  ).label;
}

export function findLabelByValue(_value: string, arr: TLabelValue[]): string {
  if (!_value || !arr) return '';
  return arr.find(({ value }) => _value === value).label;
}

/**
 * Checks values of the required fields over the map.
 * @param fields
 * @param requiredFieldsMap
 */
export function getRequiredError(
  fields: TFormFields,
  requiredFieldsMap: Record<string, string>
): string[] {
  const requiredErrors = [];
  const requiredFieldsArray = Object.keys(requiredFieldsMap);

  const checkFields = ({ name, value }: TFormField): void => {
    const fieldName = name[0];

    if (requiredFieldsArray.includes(fieldName) && !value) {
      requiredErrors.push(
        `Field '${requiredFieldsMap[fieldName]}' is compulsory!`
      );
    }
  };

  Object.values(fields).forEach(checkFields);
  return requiredErrors;
}

export function validateByMap(
  fields: TFormFields,
  fieldsMap: Record<string, string>,
  onOk: () => void
): void {
  const errors = extractFieldsErrors(fields);
  const requiredErrors = getRequiredError(fields, fieldsMap);

  if (errors.length === 0 && requiredErrors.length === 0) {
    return onOk();
  }
  showFieldsErrors(requiredErrors.concat(errors));
}
