export enum ESessionActions {
  SESSION_CHECK_REQUEST = 'session/SESSION_CHECK_REQUEST',
  SESSION_CHECK_SUCCESS = 'session/SESSION_CHECK_SUCCESS',
  SESSION_CHECK_FAIL = 'session/SESSION_CHECK_FAIL',
  SESSION_CHECK_UNAUTH = 'session/SESSION_CHECK_UNAUTH',

  SESSION_SET_USER = 'session/SESSION_SET_USER',
  STORE_REDIRECT_PATH = 'session/STORE_REDIRECT_PATH',
  CLEAR_REDIRECT_PATH = 'session/CLEAR_REDIRECT_PATH'
}
