function storageAvailable(type) {
	let storage;
	
	try {
		storage = window[type];
		const x = '__storage_test__';
		storage.setItem(x, x);
		storage.removeItem(x);
		return true;
	} catch (e) {
		return e instanceof DOMException && (
				// everything except Firefox
			e.code === 22
			// Firefox
			|| e.code === 1014
			// test name field too, because code might not be present
			// everything except Firefox
			|| e.name === 'QuotaExceededError'
			// Firefox
			|| e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
			// acknowledge QuotaExceededError only if there's something already stored
			&& storage.length !== 0;
	}
}


export function readData(key) {
	const store = window.localStorage;
	
	if (!storageAvailable('localStorage')) {
		window.console.warn('LocalStorage is not available!');
		return null;
	}
	
	const value = store.getItem(key);
	if (!value) return null;
	
	return JSON.parse(value);
}


export function writeData(key, value) {
	const store = window.localStorage;
	
	if (!storageAvailable('localStorage')) {
		window.console.warn('LocalStorage is not available!');
		return null;
	}
	
	return store.setItem(key, JSON.stringify(value));
}
