import PropTypes from 'prop-types';
import { createSelector } from 'reselect';

import { formItemProps, itemProps2formItems } from '../../other/formUtils/utils';
import { LOCALE } from '../../other/config';

import { EReportPeriod, TReport } from '../../types/report';
import { TCompany } from '../../types/company';
import { TCountry } from '../../types/dictionaries';
import { TFormFields } from '../../types/forms';
import { THandler, TIdLocationDict, TLabelValue } from '../../types/other';
import { TPrefetchState } from '../../store/dictionaries/prefetch';
import { TUIModel } from '../../store/ui/uiModel';


export type TCompanyEditorProps = {
  companies: TLabelValue[];
  company: TCompany;
  countries: TCountry[];
  fields: TFormFields;
  isLoading: boolean;
  isTouched: boolean;
  labels: TLabelValue[];
  onCancel: THandler;
  onChange: (fields: TFormFields) => void;
  onRemove: () => void;
  onReportAdd: (selectedYear: number, selectedPeriod: EReportPeriod) => void;
  onReportChange: (year: number, period: EReportPeriod, field: string, _value: string) => void;
  onReportRemove: (year: number, period: EReportPeriod) => void;
  onSave: THandler;
  reports: TReport[];
  statuses: TLabelValue[];
  ui: TUIModel;
};
export const companyEditorPropTypes = {
  fields: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  isTouched: PropTypes.bool,
  labels: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onEmployeeAdd: PropTypes.func,
  onRemove: PropTypes.func.isRequired,
  onReportAdd: PropTypes.func.isRequired,
  onReportChange: PropTypes.func.isRequired,
  onReportRemove: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,

  company: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  companies: PropTypes.arrayOf(PropTypes.object),
  countries: PropTypes.array,
  reports: PropTypes.array,
  statuses: PropTypes.array
};


const formItemsProps = [
  {
    ...formItemProps,
    label: 'Name',
    name: 'name',
    rules: [{ required: true, message: 'Name is required!' }]
  }, {
    ...formItemProps,
    label: 'Registration No.',
    name: 'registrationNumber',
    rules: [{ required: true, message: 'Registration No. is required!' }]
  }, {
    ...formItemProps,
    label: 'Address',
    name: 'address',
    rules: [{ required: true, message: 'Address is required!' }]
  }, {
    ...formItemProps,
    label: 'Post Code',
    name: 'postcode',
    rules: [{ required: true, message: 'Post Code is required!' }]
  }, {
    ...formItemProps,
    label: 'City',
    name: 'city',
    rules: [{ required: true, message: 'City is required!' }]
  }
];

export const formItems = formItemsProps.map(itemProps2formItems);

export const countryProps = {
  ...formItemProps,
  label: 'Country',
  name: 'country',
  rules: [{ required: true, message: 'Country is required!' }]
};
export const groupProps = {
  ...formItemProps,
  label: 'Group',
  name: 'group'
};
export const saProps = {
  ...formItemProps,
  label: 'Supported Apps',
  name: 'supportedApps'
};
export const statusProps = {
  ...formItemProps,
  label: 'Status',
  name: 'status'
  // rules: [{ required: true, message: 'Status is required!' }]
};

export enum EDefaultCompanyLabels {
  AQUAFACTS = 'AQUAFACTS',
  FISHFACTS = 'FISHFACTS'
}
const MAX_LABEL_COUNT = 4;
export const requiredCompanyLabels = Object.keys(EDefaultCompanyLabels);

export const labelsProps = {
  ...formItemProps,
  validateTrigger: 'onChange',
  label: 'Labels',
  name: 'labels',
  rules: [
    () => ({
      validator(rule, value) {
        if (!value || value.length === 0) return Promise.resolve();

        if (value.includes(requiredCompanyLabels[0]) &&
          value.includes(requiredCompanyLabels[1])
        ) {
          return Promise.reject(
            `Can contain only one of the two: ${requiredCompanyLabels[0]}, ${requiredCompanyLabels[1]}.`
          );
        }

        if (value.includes(requiredCompanyLabels[0]) ||
          value.includes(requiredCompanyLabels[1])
        ) {
          return value.length > MAX_LABEL_COUNT
            ? Promise.reject(`Maximum of ${MAX_LABEL_COUNT} labels allowed.`)
            : Promise.resolve();
        }

        return Promise.reject(
          `At least one of ${requiredCompanyLabels[0]}, ${requiredCompanyLabels[1]} should be specified.`
        );
      }
    })
  ]
};

export enum ETabs {
  REGISTRATION_TAB = 1,
  REPORT_TAB = 2,
  EMPLOYEES_TAB = 3,
  PHOTOS_TAB = 4,
  DEFAULT_TAB = 1
}

const statusesSelector = (dictionaries: TPrefetchState): TIdLocationDict[] => dictionaries.companyStatuses;
const mapArr2 = (arr: (TIdLocationDict | TIdLocationDict)[]): TLabelValue[] => {
  if (!arr) return [];
  return arr.map(
    ({ id, value }: (TIdLocationDict | TIdLocationDict)): TLabelValue => ({ label: value[LOCALE], value: id })
  );
};

export const selectMappedStatuses = createSelector(
  statusesSelector,
  (s: TIdLocationDict[]): TLabelValue[] => mapArr2(s)
);

export const requiredFieldsMap = {
  name: 'Name',
  registrationNumber: 'Registration No.',
  address: 'Address',
  postcode: 'Post Code',
  city: 'City',
  country: 'Country'
};
