import message from 'antd/lib/message';
import { TAction } from './types';

/**
 * Creates a set of request-success-error async action creators by given actions group name.
 * Each of the creators accepts optional data or error-data parameters.
 * S - domain state slice (helps checking payload type).
 */

export function createAsyncActions<S>(actionGroupName: string): {
  request: (data?: Partial<S>) => TAction<S, string>;
  success: (data?: Partial<S>) => TAction<S, string>;
  error: (e: any, data?: Partial<S>) => TAction<S, string>;
} {
  if (typeof actionGroupName !== 'string') {
    throw new TypeError('ActionFactory: bad actionGroupName type');
  }

  const request = (data: Partial<S> = {}): TAction<S, string> => ({
    type: `${actionGroupName}_STARTED`,
    payload: {
      isPending: true,
      error: null,
      ...data
    }
  });

  const success = (data: Partial<S> = {}): TAction<S, string> => ({
    type: `${actionGroupName}_DONE`,
    payload: {
      isPending: false,
      error: null,
      ...data
    }
  });

  const error = (error: Error, data: Partial<S> = {}): TAction<S, string> => {
    const type = `${actionGroupName}_FAILED`;
    const msg = `${type}\n${error || 'Network error!'}`;
    window.console.error(msg);
    message.error(`Error: ${error.message}`);

    return {
      type: type,
      payload: {
        isPending: false,
        error: error,
        ...data
      }
    };
  };

  return {
    request: request,
    success: success,
    error: error
  };
}
