import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import './index.scss';
import App from './App';
import { runSessionService } from './services/session';
import store, { history } from './store/store';


const root = createRoot(document.getElementById('root'));
const elem = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>
);


runSessionService(store);
root.render(elem);
