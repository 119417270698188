import React from 'react';
import Drawer from 'antd/lib/drawer';

import './Aside.scss';
import Navigation from './Navigation';

type Props = {
  isVisible: boolean;
  onCollapse: () => void;
};


const Aside = ({ isVisible, onCollapse }: Props) => {
  return (
    <>
      <aside className="Aside">
        <Navigation />
      </aside>

      <Drawer
        className="Aside__tablet"
        closable={false}
        getContainer={false}
        placement="left"
        open={isVisible}
        onClose={onCollapse}
      >
        <div className="Aside__nav" onClick={onCollapse}>
          <Navigation />
        </div>
      </Drawer>
    </>
  );
};


export default Aside;
