import message from 'antd/lib/message';
import store from '../store';
import { EUserAuthority } from '../../types/userInfo';


export const fetchAction = (type) => ({
	type,
	payload: {
    isPending: true,
    error: null
  }
});
export const successAction = (type, payload = {}) => ({
	type,
	payload: {
    isPending: false,
    error: null,
    ...payload,
  }
});
export const failAction = (type, error) => {
  window.console.error(type + '\n', error);
  return {
    type,
    payload: {
      isPending: false,
      error: error
    }
  };
};


export function isAdmin(user) {
	if (!user || !user.userInfo.authorities) return false;
	
	return user.userInfo.authorities.includes(EUserAuthority.ADMIN);
}

export function areEqual(x, y) {
	return (x && JSON.stringify(x)) === (y && JSON.stringify(y));
}

export function isDictionary(x) {
	if (!x) return false;
	return typeof x === 'object' && !x.filter;
}


export function deepClone(data) {
	if (!data) return data;
	return JSON.parse(JSON.stringify(data));
}

export const handleError = (type) => (error) => {
  const msg = error.message || 'Network error!';
  store.dispatch(failAction(type, msg));

  window.console.error(error);
  message.error(msg, 7);
};
