import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { Loading } from './common/Loading/Loading';
import { ROUTES } from '../other/config';


const ProtectedRoute = ({ component: Component, isPending, isLogged, ...rest }) => {
	if (isPending) return <Loading />;
	
	return (
		<Route {...rest} render={(props) => (
			isLogged
				? <Component {...props} />
				: <Redirect to={ROUTES.LOGIN} />
		)} />
	);
};


ProtectedRoute.propTypes = {
	isLoading: PropTypes.bool,
	isPending: PropTypes.bool,
};

const mapStateToProps = ({ session }) => ({
	isPending: session.isPending,
	isLogged: !!session.user
});

export default connect(mapStateToProps)(ProtectedRoute);
