import throttle from 'lodash.throttle';
import store, { history } from '../store';
import { TAction } from '../_utils/types';
import { TUIModel } from './uiModel';

const THROTTLE_INTERVAL = 300;
enum EUIActions {
  WINDOW_RESIZE = 'ui/WINDOW_RESIZE'
}

export function watchResize() {
  const resizeDelegate = throttle(handleResize, THROTTLE_INTERVAL);
  history.listen(handleResize);
  window.addEventListener('resize', resizeDelegate);
  handleResize();
}

function handleResize() {
  setTimeout(updateSizesAction);
}

function updateSizesAction() {
  const action: TAction<TUIModel, EUIActions> = {
    type: EUIActions.WINDOW_RESIZE,
    payload: {
      height: window.innerHeight,
      width: window.innerWidth
    }
  };

  store.dispatch(action);
}
