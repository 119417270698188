import { init } from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { CODEBASE_NAME, VERSION } from '../other/config';
import MessagingService from './messaging';
import packageJson from '../../package.json';
import { sessionCheck } from '../store/session/sessionActions';
import { watchResize } from '../store/ui/uiActions';

const styles = [
  'color: white',
  'background-color: #96ceb4',
  'font-size: 14px'
].join(';');
window.console.log(`%c FishFacts Admin v${packageJson.version} `, styles);

/**/
if (window.location.hostname.startsWith('admin')) {
  init({
    dsn: 'https://9bbcb0fcb2a34163a91d44c7cb00b613@o232276.ingest.sentry.io/5407282',
    release: `${CODEBASE_NAME}@${VERSION}`,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
  });
}

/**/
export function runSessionService(store) {
  watchResize();
  new MessagingService();
  
  store.dispatch(sessionCheck());
}
