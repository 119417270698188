import { BREAKPOINT } from './config';
import { TPhotoSet } from '../types/media';
import { TUIModel } from '../store/ui/uiModel';

/**
 * Generates a random string of 11 characters.
 */
export function randomString(): string {
  return Math.random().toString(36).slice(2);
}

/**
 * A robust string comparator.
 */
export function stringComparator(a: string, b: string): number {
  if (!a || typeof a !== 'string') return -1;
  if (!b || typeof b !== 'string') return 1;
  return a.localeCompare(b);
}

export const dateComparator = (a: string, b: string) => {
  const result = Date.parse(a) - Date.parse(b);
  return isNaN(result) ? stringComparator(a, b) : result;
};

export function toUpperSnakeCase(value: string): string {
  return value.toUpperCase().replace(/ - /g, '-').replace(/ /g, '_');
}

/**
 * Returns the next lower position among all the photos.
 * The lower number is, the higher is the position of a photo.
 */
export function getHigherPosition(photos: TPhotoSet[]): number {
  if (!photos || photos.length === 0) return 1;

  const positions: number[] = photos.map(({ position }: TPhotoSet) => position);
  return Math.min(...positions) - 1;
}


export function getHeaderHeight(): number {
  const val = getComputedStyle(document.documentElement).getPropertyValue('--header-height');
  return parseInt(val);
}

/**
 * Returns space, that is taken by the elements, placed above .ant-table-body.
 * Used to calculate available table Y scroll space.
 */
export function getTakenSpace(bottomPadding = 1): number {
  const TABLE_HEADER_HEIGHT = 39;
  const el = document.querySelector('.ant-table');
  if (!el) return window.innerHeight * 0.7;

  const { height, y } = el.getBoundingClientRect();
  return window.innerHeight - y - TABLE_HEADER_HEIGHT - bottomPadding;
}


export function isNarrowViewport({ height, width }: TUIModel) {
  return height < BREAKPOINT.BP992 && width < BREAKPOINT.BP576 ||
    height < BREAKPOINT.BP576 && width < BREAKPOINT.BP992;
}
