import React from 'react';
import { connect } from 'react-redux';
import {
  faBorderNone,
  faChartPie,
  faIdCard,
  faRegistered,
  faShip,
  faUserFriends,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FunctionOutlined from '@ant-design/icons/FunctionOutlined';
import { NavLink } from 'react-router-dom';

import './Navigation.scss';
import { ROUTES } from '../../other/config';

import { EUserAuthority } from '../../types/userInfo';
import { TStore } from '../../store/storeModel';

/**/
type Props = {
  canManageUsers: boolean;
  isDeveloper: boolean;
};

/**
 *
 * @param canManageUsers
 * @param isDeveloper
 * @constructor
 */
const Navigation = ({ canManageUsers, isDeveloper }: Props) => (
  <div className="Navigation">
    <div className="Navigation__logo">
      <img src="/assets/Logo.png" alt="Logo" />
    </div>

    <ul className="ant-menu ant-menu-root ant-menu-inline ant-menu-dark Navigation__menu" role="menu">
      <li className="ant-menu-item ant-menu-item-only-child" role="menuitem">
        <span className="ant-menu-title-content">
          <NavLink
            to={ROUTES.HOME}
            exact={true}
            activeClassName="Navigation__link--active"
            className="Navigation__link"
          >
          <FontAwesomeIcon
            icon={faShip}
            className="anticon"
            size="1x"
            color="white"
          />
          <span className="Navigation__item">Vessels</span>
        </NavLink>
        </span>
      </li>

      <li className="ant-menu-item ant-menu-item-only-child" role="menuitem">
        <span className="ant-menu-title-content">
          <NavLink
            to={ROUTES.COMPANIES}
            exact={true}
            activeClassName="Navigation__link--active"
            className="Navigation__link"
          >
          <FontAwesomeIcon
            icon={faRegistered}
            className="anticon"
            size="1x"
            color="white"
          />
          <span className="Navigation__item">Companies</span>
        </NavLink>
        </span>
      </li>

      <li className="ant-menu-item ant-menu-item-only-child" role="menuitem">
        <span className="ant-menu-title-content">
          <NavLink
            to={ROUTES.PEOPLE}
            exact={true}
            activeClassName="Navigation__link--active"
            className="Navigation__link"
          >
          <FontAwesomeIcon
            icon={faUserFriends}
            className="anticon"
            size="1x"
            color="white"
          />
          <span className="Navigation__item">People</span>
        </NavLink>
        </span>
      </li>

      {canManageUsers && (
        <li className="ant-menu-item ant-menu-item-only-child" role="menuitem">
          <span className="ant-menu-title-content">
            <NavLink
              to={ROUTES.USERS}
              exact={true}
              activeClassName="Navigation__link--active"
              className="Navigation__link"
            >
              <FontAwesomeIcon
                icon={faIdCard}
                className="anticon"
                size="1x"
                color="white"
              />
              <span className="Navigation__item">Users</span>
            </NavLink>
          </span>
        </li>
      )}

      {canManageUsers && (
        <li className="ant-menu-item ant-menu-item-only-child" role="menuitem">
          <span className="ant-menu-title-content">
              <NavLink
                to={ROUTES.USER_GROUPS}
                exact={true}
                activeClassName="Navigation__link--active"
                className="Navigation__link"
              >
                <FontAwesomeIcon
                  icon={faUsers}
                  className="anticon"
                  size="1x"
                  color="white"
                />
                <span className="Navigation__item">User Groups</span>
              </NavLink>
          </span>
        </li>
      )}

      <li className="ant-menu-item ant-menu-item-only-child" role="menuitem">
        <span className="ant-menu-title-content">
          <NavLink
            to={ROUTES.FARMS}
            exact={true}
            activeClassName="Navigation__link--active"
            className="Navigation__link"
          >
          <FontAwesomeIcon
            icon={faBorderNone}
            className="anticon"
            size="1x"
            color="white"
          />
          <span className="Navigation__item">Farms</span>
        </NavLink>
        </span>
      </li>

      <li className="ant-menu-item ant-menu-item-only-child" role="menuitem">
        <span className="ant-menu-title-content">
          <NavLink
            to={ROUTES.FARM_LICENCES}
            exact={true}
            activeClassName="Navigation__link--active"
            className="Navigation__link"
          >
          <FontAwesomeIcon
            icon={faChartPie}
            className="anticon"
            size="1x"
            color="white"
          />
          <span className="Navigation__item">Farm Licences</span>
        </NavLink>
        </span>
      </li>

      {isDeveloper && (
        <li className="ant-menu-item ant-menu-item-only-child" role="menuitem">
          <span className="ant-menu-title-content">
            <NavLink
              to={ROUTES.DEVELOPER}
              exact={true}
              activeClassName="Navigation__link--active"
              className="Navigation__link"
            >
              <FunctionOutlined />
              <span className="Navigation__item">Developer</span>
            </NavLink>
          </span>
        </li>
      )}
    </ul>
  </div>
);

/**
 *
 */
const mapStateToProps = ({ session }: TStore) => {
  const authorities = session.user?.userInfo.authorities;
  return {
    canManageUsers: authorities.includes(
      EUserAuthority.ADMIN_USER_MANAGEMENT
    ),
    isDeveloper: authorities.includes(EUserAuthority.DEVELOPER)
  };
};

export default connect(mapStateToProps)(Navigation);
