import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AuthoredRoute from '../components/AuthoredRoute';
import { ROUTES } from './config';
import { EUserAuthority } from '../types/userInfo';

const CompanyEditorPage = lazy(() => import('../pages/CompanyEditorPage/CompanyEditorPage'));
const CompanyTablePage = lazy(() => import('../pages/CompanyTablePage/CompanyTablePage'));
const DevelopersPage = lazy(() => import('../pages/DevelopersPage/DevelopersPage'));
const FarmEditorPage = lazy(() => import('../pages/FarmEditorPage/FarmEditorPage'));
const FarmLicenceEditorPage = lazy(() => import('../pages/FarmLicenceEditorPage/FarmLicenceEditorPage'));
const FarmLicencesPage = lazy(() => import('../pages/FarmLicencesPage/FarmLicencesPage'));
const FarmsTablePage = lazy(() => import('../pages/FarmsTablePage/FarmsTablePage'));
const PersonEditorPage = lazy(() => import('../pages/PersonEditorPage/PersonEditorPage'));
const PeopleTablePage = lazy(() => import('../pages/PeopleTablePage/PeopleTablePage'));
const TestPage = lazy(() => import('../pages/TestPage/TestPage'));
const UserEditorPage = lazy(() => import('../pages/UserEditorPage/UserEditorPage'));
const UserGroupEditorPage = lazy(() => import('../pages/UserGroupEditorPage/UserGroupEditorPage'));
const UserGroupsPage = lazy(() => import('../pages/UserGroupsPage/UserGroupsPage'));
const UsersTablePage = lazy(() => import('../pages/UsersTablePage/UsersTablePage'));
const VesselEditorPage = lazy(() => import('../pages/VesselEditorPage/VesselEditorPage'));
const VesselTablePageConnected = lazy(() => import('../pages/VesselTablePage/VesselTablePage'));

/**
 *
 */
export const routing = (
  <Suspense fallback="Loading...">
    <Switch>
      <Route component={VesselTablePageConnected} exact path={ROUTES.HOME} />
      <Route component={TestPage} path={ROUTES.TEST} />
  
      <Route component={CompanyTablePage} path={ROUTES.COMPANIES} />
      <Route component={CompanyEditorPage} exact path={ROUTES.COMPANY_EDIT} />
      <Route
        path={`${ROUTES.COMPANY_EDIT}/:id`}
        render={(props) => (
          <CompanyEditorPage key={props.match.params.id} {...props} />
        )}
      />
  
      <Route component={VesselEditorPage} exact path={ROUTES.VESSEL_EDIT} />
      <Route
        path={`${ROUTES.VESSEL_EDIT}/:id`}
        render={(props) => (
          <VesselEditorPage key={props.match.params.id} {...props} />
        )}
      />
  
      <Route component={PeopleTablePage} exact path={ROUTES.PEOPLE} />
      <Route component={PersonEditorPage} exact path={ROUTES.PEOPLE_EDIT} />
      <Route
        path={`${ROUTES.PEOPLE_EDIT}/:id`}
        render={(props) => (
          <PersonEditorPage key={props.match.params.id} {...props} />
        )}
      />
      <Route component={FarmsTablePage} exact path={ROUTES.FARMS} />
      <Route component={FarmEditorPage} exact path={ROUTES.FARM_EDIT} />
      <Route
        path={`${ROUTES.FARM_EDIT}/:id`}
        render={(props) => (
          <FarmEditorPage key={props.match.params.id} {...props} />
        )}
      />
      <Route component={FarmLicencesPage} exact path={ROUTES.FARM_LICENCES} />
      <Route component={FarmLicenceEditorPage} exact path={ROUTES.LICENCE_EDITOR} />
      <Route
        path={`${ROUTES.LICENCE_EDITOR}/:id`}
        render={(props) => (
          <FarmLicenceEditorPage key={props.match.params.id} {...props} />
        )}
      />
    
      <AuthoredRoute
        component={DevelopersPage}
        path={ROUTES.DEVELOPER}
        roles={[EUserAuthority.DEVELOPER]}
      />
      <AuthoredRoute
        component={UsersTablePage}
        path={ROUTES.USERS}
        roles={[EUserAuthority.ADMIN_USER_MANAGEMENT]}
      />
      <AuthoredRoute
        component={UserEditorPage}
        exact
        path={`${ROUTES.USER_EDIT}/:id`}
        roles={[EUserAuthority.ADMIN_USER_MANAGEMENT]}
      />
      <AuthoredRoute
        component={(props) => (
          <UserEditorPage key={props.match.params.id} {...props} />
        )}
        path={ROUTES.USER_EDIT}
        roles={[EUserAuthority.ADMIN_USER_MANAGEMENT]}
      />
  
      <AuthoredRoute
        component={UserGroupsPage}
        path={ROUTES.USER_GROUPS}
        roles={[EUserAuthority.ADMIN_USER_MANAGEMENT]}
      />
      <AuthoredRoute
        component={UserGroupEditorPage}
        exact
        path={`${ROUTES.USER_GROUP_EDIT}/:id`}
        roles={[EUserAuthority.ADMIN_USER_MANAGEMENT]}
      />
      <AuthoredRoute
        component={(props) => (
          <UserGroupEditorPage key={props.match.params.id} {...props} />
        )}
        path={ROUTES.USER_GROUP_EDIT}
        roles={[EUserAuthority.ADMIN_USER_MANAGEMENT]}
      />
  
      <Redirect to={ROUTES.HOME} />
    </Switch>
  </Suspense>
);
