import { dictionariesInitialState } from './dictionaries/prefetchInitialState';
import { farmInitialState } from './farm/farmInitialState';
import { farmLicenceInitialState } from './farmLicence/farmLicenceInitialState';
import { farmLicencesInitialState } from './farmLicences/farmLicencesInitialState';
import { farmsInitialState } from './farms/farmsInitialState';
import { loginInitialState } from './login/loginInitalState';
import { sessionInitialState } from './session/sessionInitialState';
import { searchInitialState } from './search/searchInitialState';
import { companiesInitialState } from './companies/companiesInitialState';
import { companyInitialState } from './company/companyInitialState';
import { peopleInitialState } from './people/peopleInitialState';
import { personInitialState } from './person/personInitialState';
import { vesselInitialState } from './vessel/vesselInitialState';
import { ownersInitialState } from './owners/ownersInitialState';
import { reportsInitialState } from './reports/reportsInitialState';
import { uiInitialState } from './ui/uiInitialState';
import { userEditorInitialState } from './userEditor/userEditorInitialState';
import { userGroupEditorInitialState } from './userGroupEditor/userGroupEditorInitialState';
import { userGroupsInitialState } from './userGroups/userGroupsInitialState';
import { usersInitialState } from './users/usersInitialState';
import { vesselsInitialState } from './vessels/vesselsInitialState';
import { TStore } from './storeModel';

export const storeInitialState: TStore = {
  // General section
  dictionaries: dictionariesInitialState,
  login: loginInitialState,
  session: sessionInitialState,
  search: searchInitialState,
  ui: uiInitialState,
  // Company
  companies: companiesInitialState,
  company: companyInitialState,
  // Farm
  farm: farmInitialState,
  farmLicence: farmLicenceInitialState,
  farmLicences: farmLicencesInitialState,
  farms: farmsInitialState,
  // People
  people: peopleInitialState,
  person: personInitialState,
  // Users
  userEditor: userEditorInitialState,
  userGroupEditor: userGroupEditorInitialState,
  userGroups: userGroupsInitialState,
  users: usersInitialState,
  // Vessel
  owners: ownersInitialState,
  reports: reportsInitialState,
  vessel: vesselInitialState,
  vessels: vesselsInitialState
};
