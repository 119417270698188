import { assignCompanies } from '../dictionaries/prefetchActions';
import { ENDPOINTS } from '../../other/config';
import { http } from '../../services/api';
import MessagingService from '../../services/messaging';
import { requiredCompanyLabels } from '../../pages/CompanyEditorPage/helpers';
import { stringComparator } from '../../other/utils';
import { successAction } from '../_utils/helpers';

import { ECompaniesActions, fetchActs, removeActs } from './companiesConstants';
import { TCompany } from '../../types/company';
import { TStore } from '../storeModel';


export function companiesFetch() {
	return (dispatch) => {
		dispatch(fetchActs.request());

		http(ENDPOINTS.COMPANIES)
			.then((companies: TCompany[]) => {
        dispatch(assignCompanies(companies));
        dispatch(fetchActs.success({
          companies: handleCompanies(companies)
        }));
			})
      .catch((e: Error) => dispatch(fetchActs.error(e)));
	};
}


export function removeCompanyAction(companyId: number) {
  return (dispatch, getState) => {
    const { companies: { companies } }: TStore = getState();
    const url = `${ENDPOINTS.COMPANIES}/${companyId}`;
    dispatch(removeActs.request());

    http(url, { method: 'DELETE' })
      .then(() => {
        const update = companies.filter(({ id }: TCompany) => id !== companyId);
        dispatch(removeActs.success({
          companies: update
        }));
      })
      .catch((e: Error) => dispatch(removeActs.error(e)));
  };
}


export function filterCompaniesAction(typed: string) {
	return (dispatch, getState) => {
		const { companies: { searchText, companies } }: TStore = getState();
		if (!companies.length || typed === searchText) return;

		dispatch({
			type: ECompaniesActions.FILTER_TYPED,
			payload: { searchText: typed }
		});
	};
}


export function companiesInsert(company: TCompany) {
  return (dispatch, getState) => {
    const { companies: { companies } }: TStore = getState();
    const list: TCompany[] = [...companies, company].sort(sortCompanies);
    MessagingService.postMessage(company, ECompaniesActions.INSERT_COMPANY);

    dispatch({
      type: ECompaniesActions.INSERT_COMPANY,
      payload: { companies: list }
    });
  };
}


export function withdrawCompanyAction(companyId: number) {
  return (dispatch, getState) => {
    const { companies: { companies } }: TStore = getState();
    const update = companies.filter(({ id }: TCompany) => id !== companyId);

    dispatch(successAction(ECompaniesActions.WITHDRAW_COMPANY, {
      companies: update
    }));
  };
}


export function companiesUpdate(company: TCompany) {
  return (dispatch, getState) => {
    const { companies }: TStore = getState();
    const updater = (v: TCompany): TCompany => v.id === company.id ? company : v;

    dispatch({
      type: ECompaniesActions.UPDATE_COMPANIES,
      payload: {
        companies: companies.companies.map(updater)
      }
    });
  };
}


export const sortCompanies = (a: TCompany, b: TCompany): number => stringComparator(a.name, b.name);


export function handleCompanies(companies: TCompany[]): TCompany[] {
  return companies.sort(sortCompanies)
    .filter(({ name }: TCompany) => name)
    .map((c: TCompany): TCompany => ({
      ...c,
      labels: (c.labels as any || '').split(',')
        .filter(Boolean)
        .sort((a: string, b: string) =>
          Number(requiredCompanyLabels.includes(b)) - Number(requiredCompanyLabels.includes(a))
        )
    }));
}
