import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import { storeInitialState } from './storeInitialState';
import createRootReducer from './reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const history = createBrowserHistory();

const logger = createLogger({
  collapsed: (getState, action, logEntry) =>
    !(action && action.payload && action.payload.error) || logEntry.error
});

export default createStore(
  createRootReducer(history),
  storeInitialState,
  composeEnhancers(applyMiddleware(routerMiddleware(history), thunk, logger))
);
