import React from 'react';
import PropTypes from 'prop-types';
import LogoutOutlined from '@ant-design/icons/LogoutOutlined';
import MenuUnfoldOutlined from '@ant-design/icons/MenuUnfoldOutlined';
import MenuFoldOutlined from '@ant-design/icons/MenuFoldOutlined';

import Search from '../Search/Search';
import './Header.scss';


const Header = ({ collapsed, logout, onCollapse }) => {
  const Trigger = collapsed ? MenuFoldOutlined : MenuUnfoldOutlined;
  
  return (
    <header className="Header">
      <Trigger className="Header__icon Header__trigger"	onClick={onCollapse} />
      
      <div className="Header__search">
        <Search />
      </div>
      
      <LogoutOutlined className="Header__icon" onClick={logout} />
    </header>
  );
};


Header.propTypes = {
  collapsed: PropTypes.bool,
	logout: PropTypes.func.isRequired,
	onCollapse: PropTypes.func.isRequired,
};

export default Header;
