import { basicInitialState } from '../_utils/basicInitialState';
import { TPrefetchState } from './prefetch';

export const dictionariesInitialState: TPrefetchState = {
  ...basicInitialState,
  companies: [],
  companyLabels: [],
  companyRoles: [],
  companyStatuses: [],
  countries: [],
  countriesISO: [],
  engines: [],
  fishFarmLicenceTypes: [],
  fishFarmStatuses: [],
  languages: [],
  manufacturers: [],
  measurementUnits: [],
  species: [],
  userAuthorities: [],
  vesselClassifications: [],
  vesselStatuses: [],
  vesselTypes: [],
  vesselYards: []
};
