import React from 'react';
import PropTypes from 'prop-types';
import Button from 'antd/lib/button';
import Checkbox from 'antd/lib/checkbox';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import LockOutlined from '@ant-design/icons/LockOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';

import './LoginPage.scss';
import { minMaxLenValidator } from '../../other/formUtils/validators';

const loginRules = minMaxLenValidator('User name', 50, 2);
const passwordRules = minMaxLenValidator('Password', 50, 6);


class LoginPage extends React.Component {
  
  handleSubmit = ({ username, password, shouldKeepLogged }) => {
    const { isPending, login } = this.props;
    isPending || login(username, password, shouldKeepLogged);
  };
	
	render() {
    const keepLogged = (
      <Form.Item name="shouldKeepLogged" valuePropName="checked">
        <Checkbox>Remember me</Checkbox>
      </Form.Item>
    );
    
		return (
			<div className="LoginPage">
				<Form onFinish={this.handleSubmit} className="LoginPage__form">
					<h2 className="LoginPage__title">Please, log in</h2>
          
          <Form.Item
            name="username"
            rules={loginRules}
            validateTrigger="onBlur"
          >
            <Input
              addonBefore={<UserOutlined className="LoginPage__icon" />}
              placeholder="Username"
            />
          </Form.Item>
          
          <Form.Item
            name="password"
            rules={passwordRules}
            validateTrigger="onBlur"
          >
            <Input.Password
              addonBefore={<LockOutlined className="LoginPage__icon" />}
              placeholder="Password"
            />
          </Form.Item>
          
          {keepLogged}
					
          <Button
            type="primary"
            htmlType="submit"
            loading={this.props.isPending}
            className="ant-btn ant-btn-primary LoginPage__submit"
          >
            Log in
          </Button>
				</Form>
			</div>
		);
	}
}


LoginPage.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired
};
export default LoginPage;
