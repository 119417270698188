import React from 'react';
import PropTypes from 'prop-types';
import AutoComplete from 'antd/lib/auto-complete';
import { connect } from 'react-redux';
import Input from 'antd/lib/input';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';

import './Search.scss';
import { getSearchOptions } from './utils';
import { searchRequestAction, searchSelectResult } from '../../store/search/searchActions';


const Search = ({ isPending, onSearch, onSelect, query, results }) => {
  const icon = isPending ? <LoadingOutlined /> : <SearchOutlined />;
	const options = getSearchOptions(results, query);
  
  return (
    <AutoComplete
			allowClear={true}
      autoFocus={true}
      className="Search"
      popupClassName="Search__dropdown"
      dropdownMatchSelectWidth={true}
      options={options}
      onChange={onSearch}
      onSelect={onSelect}
			value={query}
    >
      <Input placeholder="Search" suffix={icon} />
    </AutoComplete>
  );
};


Search.propTypes = {
  isPending: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
	query: PropTypes.string,
	results: PropTypes.array,
};

const mapStateToProps = (state) => {
  const { search } = state;
  return {
    isPending: search.isPending,
    query: search.searchQuery,
    results: search.searchResults,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSearch: (...args) => dispatch(searchRequestAction(...args)),
  onSelect: (...args) => dispatch(searchSelectResult(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
