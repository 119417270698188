import { push } from 'connected-react-router';

import { getToken } from './auth';
import { ROUTES } from '../other/config';
import { sessionSetUser } from '../store/session/sessionActions';
import store from '../store/store';

const defaultParams = { credentials: 'include' };


export function http(endpoint, params, options = {}) {
	return fetch(
	  getUrl(endpoint, options),
    getParams(params)
  )
		.then((res) =>
			options.isRaw ? handleRawResponse(res, endpoint) : handleResponse(res, endpoint)
		)
		.catch((error) => {
			window.console.error(error.message);
			throw error;
		});
}


function getUrl(endpoint, options) {
  const { baseUrl, isAdmin = true } = options;
  const url = isAdmin
    ? `${process.env.REACT_APP_BASE_URL}/admin-api${endpoint}`
    : `${process.env.REACT_APP_BASE_URL}/api/v3${endpoint}`;
  return baseUrl ? `${baseUrl}${endpoint}` : url;
}


function getParams(params = {}) {
  const { headers, ...restParams } = params;
  const _headers = {
    'X-Auth-Token': getToken(),
    ...headers || {}
  };
  return {
    ...defaultParams,
    ...restParams,
    headers: _headers
  };
}


function handleRawResponse(res, endpoint) {
	const { ok, status, statusText } = res;
	!ok && window.console.error(`${status}: ${statusText} @ ${endpoint}`);
	return res;
}


async function handleResponse(res, endpoint) {
	const { code, data, errors, message } = await res.json();
	
	if (res.status === 401) {
		store.dispatch(sessionSetUser());
		store.dispatch(push(ROUTES.LOGIN));
		throw new Error(`ENDPOINT: ${endpoint} -- 401 -- Unauthorized`);
	}
	
	if (errors) {
    if (errors.some(({ text }) => text)) {
      const msg = errors.map(({ text }) => text)
        .filter(Boolean)
        .join('. ');
      
      throw new Error(msg);
    }
    
		const msg = `ENDPOINT: ${endpoint} -- MSG: ${message} -- ERR: ${JSON.stringify(errors)}`;
		throw new Error(msg);
		
	} else if (code !== 0) {
		throw new Error(message);
	}
	
	return data;
}
