import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import LoginPage from './LoginPage';
import { loginRequest } from '../../store/login/loginActions';
import { ROUTES } from '../../other/config';


const LoginPageWrapper = (props) => { // todo revert to isLoggedIn
	return props.isLoggedIn
		? <Redirect to={ROUTES.HOME} />
		: <LoginPage {...props} />;
};


const mapStateToProps = (state) => {
	const {
		login: { isPending },
		session: { user }
	} = state;

	return {
		isLoggedIn: !!user,
		isPending,
	};
};

const mapDispatchToProps = (dispatch) => ({
	login: (...args) => dispatch(loginRequest(...args)),
});

LoginPageWrapper.propTypes = {
  isLoggedIn: PropTypes.bool,
  isPending: PropTypes.bool,
  login: PropTypes.func
};


export default connect(mapStateToProps, mapDispatchToProps)(LoginPageWrapper);
