import { configureScope } from '@sentry/react';
import message from 'antd/lib/message';

import { ENDPOINTS } from '../../other/config';
import { failAction, fetchAction, isAdmin, successAction } from '../_utils/helpers';
import { http } from '../../services/api';
import { saveIfKeepLogged, saveToken } from '../../services/auth';
import { sessionSetUser } from '../session/sessionActions';
import store from '../store';
import { ELoginActions } from './loginConstants';


/**
 * Fired by a login form.
 * @param username
 * @param password
 * @param shouldKeepLogged
 */
export function loginRequest(username: string, password: string, shouldKeepLogged: boolean) {
	return (dispatch) => {
    saveIfKeepLogged(shouldKeepLogged);
		dispatch(fetchAction(ELoginActions.LOGIN_REQUEST));

		sendLoginRequest(username, password)
			.then(handleLoginResponse)
			.catch(handleLoginError);
	};
}


/**
 * Fired on logOut. Clears the user data.
 */
export function logoutRequest() {
	return (dispatch) => {
    saveIfKeepLogged(false);
    saveToken();

		dispatch(fetchAction(ELoginActions.LOGOUT_REQUEST));
		dispatch(sessionSetUser(null));

		http(ENDPOINTS.LOGOUT, {
			method: 'DELETE',
		}, { isAdmin: false })
			.then(() => dispatch(
			  successAction(ELoginActions.LOGOUT_SUCCESS))
      )
			.catch((e: Error) => dispatch(
			  failAction(ELoginActions.LOGOUT_FAIL, e.message))
      );
	};
}


/**
 * Forms login request body and hits the login endpoint.
 * @param username
 * @param password
 */
function sendLoginRequest(username: string, password: string): Promise<any> {
	const params = new FormData();
	params.append('username', username);
	params.append('password', password);

	return http(ENDPOINTS.LOGIN, {
		method: 'POST',
		body: params,
	}, {
	  isAdmin: false,
    isRaw: true
	});
}


/** Login success handler */
async function handleLoginResponse(res: Response): Promise<void> {
	if (res.status === 401) {
		saveToken();
		saveIfKeepLogged(false);
		throw new Error('Bad credentials!');
	}

	if (!res || !res.ok) {
		const msg = 'Network problem. Please, retry later.';
		throw new Error(res.statusText || msg);
	}

	const { data: user } = await res.json();

	if (!isAdmin(user)) {
		saveToken();
		saveIfKeepLogged(false);

		throw new Error('You are not authorized!');
	}

	configureScope((scope) =>
    scope.setUser({
      id: String(user.userInfo.id)
    })
  );

  saveToken(user.token);
  store.dispatch(successAction(ELoginActions.LOGIN_SUCCESS));
	store.dispatch(sessionSetUser(user));
}


/** Login failure handler */
function handleLoginError(error: Error): void {
  store.dispatch({
    type: ELoginActions.LOGIN_FAIL,
    payload: {
      displayError: error.message,
      error: error.message,
      isPending: false
    }
  });

  message.error(error.message);
}
