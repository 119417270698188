import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import './App.scss';
import { Loading } from './components/common/Loading/Loading';
import LoginPageConnected from './pages/LoginPage/LoginPageConnected';
import Main from './components/Main/Main';
import ProtectedRoute from './components/ProtectedRoute';
import { ROUTES } from './other/config';
import { TStore } from './store/storeModel';

type Props = { isSessionPending: boolean; };


function App(props: Props) {
  if (props.isSessionPending) return (
    <Loading />
  );

  return (
    <div className="App">
      <Switch>
        <Route exact path={ROUTES.LOGIN} component={LoginPageConnected} />
        <ProtectedRoute component={Main} />
      </Switch>
    </div>
  );
}


const mapStateToProps = ({ session, ui }: TStore) => ({
  isSessionPending: session.isPending
});

export default connect(mapStateToProps)(App);
