import React from 'react';
import Modal from 'antd/lib/modal';
import { TFormField, TFormFields } from '../../types/forms';


/** Extracts error messages from dictionary of antd's form fields, if any. */
export function extractFieldsErrors(fields: TFormFields): string[] {
  // @ts-ignore
  return Object.values(fields)
    .map(({ errors }: TFormField): string[] | void => {
      if (!errors) return null;
      return errors;
    })
    .flat()
    .filter(Boolean);
}


export function showFieldsErrors(errors: string[]): void {
  const content = (
    <div className="ValidationErrors">
      <h4 className="ValidationErrors__header">
        Please fix the following errors/warnings:
      </h4>

      <ul className="ValidationErrors__list">
        {errors.map((message: string) => (
          <li className="ValidationErrors__item" key={message}>
            {message}
          </li>
        ))}
      </ul>
    </div>
  );

  Modal.error({
    title: 'Validation errors:',
    content: content
  });
}
