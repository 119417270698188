import { createAsyncActions } from '../_utils/createAsyncActions';
import { TCompaniesState } from './companies';

export enum ECompaniesActions {
	FETCH_COMPANIES = 'companies/FETCH_COMPANIES',
  REMOVE_COMPANY = 'companies/REMOVE_COMPANY',

	FILTER_TYPED = 'companies/FILTER_TYPED',
	INSERT_COMPANY = 'companies/INSERT_COMPANY',
	UPDATE_COMPANIES = 'companies/UPDATE_COMPANIES',
	WITHDRAW_COMPANY = 'companies/WITHDRAW_COMPANIES',
}

export const fetchActs = createAsyncActions<TCompaniesState>(ECompaniesActions.FETCH_COMPANIES);
export const removeActs = createAsyncActions<TCompaniesState>(ECompaniesActions.REMOVE_COMPANY);
