import { push } from 'connected-react-router';

import { ENDPOINTS } from '../../other/config';
import { http } from '../../services/api';

import { ESearchActions, fetchActs } from './searchConstants';
import { TSearchResult } from '../../types/search';


/**
 * Fired on search key stroke.
 * @param searchQuery
 */
export function searchRequestAction(searchQuery: string) {
  return (dispatch) => {
    dispatch({
			type: ESearchActions.SEARCH_TYPED,
			payload: {
				searchResults: null,
				searchQuery
			}
		});

    if (!searchQuery) return;

    const url = `${ENDPOINTS.SEARCH}?text=${searchQuery}`;
    dispatch(fetchActs.request());

    http(url, {}, { isAdmin: false })
      .then((res: TSearchResult[]) => dispatch(fetchActs.success({
        searchResults: res
      })))
      .catch((e: Error) => dispatch(fetchActs.error(e)));
  };
}


/**
 * Fired when user selects an item from obtained search result.
 */
export function searchSelectResult(path: string) {
  return (dispatch) => {
    dispatch({
      type: ESearchActions.SEARCH_CLEAR,
      payload: {
        searchResults: null,
        searchQuery: null
      }
    });
    dispatch(push(path));
  };
}
