import { ENDPOINTS, LOCALE } from '../../other/config';
import { http } from '../../services/api';
import { stringComparator } from '../../other/utils';

import { EPrefetchActions, fetchActs } from './prefetchConstants';
import { TCompany } from '../../types/company';
import { TCountry, TCountryISO, TLocaleSet, TUserAuthority, TVesselType } from '../../types/dictionaries';
import { TIdLocationDict, TIdName, TLabelValue } from '../../types/other';
import { TPrefetchState } from './prefetch';
import { TStore } from '../storeModel';


/**
 * Retrieves the dictionaries.
 */
export function prefetchRequest() {
	return (dispatch) => {
		dispatch(fetchActs.request());

		http(ENDPOINTS.DICTIONARIES)
			.then((dics) => dispatch(
        fetchActs.success(handleDictionaries(dics)
      )))
			.catch((e: Error) => dispatch(fetchActs.error(e)));
	};
}


export function assignCompanies(companies: TCompany[]) {
  return {
    type: EPrefetchActions.ASSIGN_COMPANIES,
    payload: { companies: mapCompanies(companies) }
  };
}


export function insertCompany({ id, name }: TCompany) {
  return (dispatch, getState) => {
    const { dictionaries: { companies } }: TStore = getState();
    const company = {
      label: name, value: id
    };
    const list = [...companies, company]
      .sort((a, b) => stringComparator(a.label, b.label));

    dispatch({
      type: EPrefetchActions.INSERT_COMPANY,
      payload: {
        companies: list
      }
    });
  };
}


export function handleDictionaries(dictionaries): Partial<TPrefetchState> {
  const {
    countries, engines,
    fishFarmLicenceTypes, fishFarmStatuses,
    languages, manufacturers,
    measurementUnits, species,
    companyLabels, companyRoles, companyStatuses,
    userAuthorities,
    vesselClassifications, vesselStatuses,
    vesselTypes, vesselYards
  } = dictionaries;

  const _countries = sortByLocale(countries) as any;

  return {
    companyLabels: companyLabels.map((l): TLabelValue => ({
      label: (l as any).name,
      value: (l as any).name
    })),
    companyRoles: sortByLocale(companyRoles),
    companyStatuses: sortByLocale(companyStatuses),
    countries: mapISOCountries(_countries),
    countriesISO: _countries,
    engines: sortByName(engines),
    fishFarmLicenceTypes: mapUnits(sortByLocale(fishFarmLicenceTypes)),
    fishFarmStatuses: mapUnits(fishFarmStatuses),
    languages: sortByLocale(languages),
    manufacturers: sortByName(manufacturers),
    measurementUnits: mapUnits(sortByLocale(measurementUnits)),
    species: mapUnits(sortByLocale(species)),
    userAuthorities: sortByName(mapAuthorities(userAuthorities)),
    vesselClassifications: sortByName(vesselClassifications),
    vesselStatuses: sortByLocale(vesselStatuses),
    vesselTypes: mapVesselTypes(vesselTypes),
    vesselYards: sortByName(vesselYards)
  };
}

/** Converts ISO type into label-value */
function mapISOCountries(countries: TCountryISO[]): TCountry[] {
  return countries
    .map((c: TCountryISO): TCountry => ({
      value: c.iso,
      label: c.value[LOCALE]
    }));
}
function mapAuthorities(authorities: TUserAuthority[] = []): TIdName[] {
  return authorities
    .map(({ id }: TUserAuthority) => ({
      id: id,
      name: id
    }))
    .sort((a: TIdName, b: TIdName): number =>
      (a.id as string).localeCompare(b.id as string)
    );
}
function sortByLocale<T extends TWithLocale>(arr: T[]): T[] {
  return arr.sort((a: T, b: T): number =>
    a.value.en_GB.localeCompare(b.value.en_GB)
  );
}
function sortByName<T extends TWithName>(arr: T[]): T[] {
  return arr.sort((a: T, b: T): number =>
    a.name.localeCompare(b.name)
  );
}
function mapUnits(units: TIdLocationDict[]): TLabelValue[] {
  return units.map((u: TIdLocationDict): TLabelValue => ({
    label: u.value.en_GB,
    value: u.id
  }));
}
function mapVesselTypes(vesselTypes: TVesselType[]): TVesselType[] {
  return sortByLocale(vesselTypes)
    .map((t: TVesselType): TVesselType => {
      if (!t.subTypes) return t;
      return {
        ...t,
        subTypes: sortByLocale(t.subTypes)
      };
    });
}
export function mapCompanies(companies: TCompany[]): TLabelValue[] {
  return companies
    .filter((c: TCompany): boolean =>
      c.name && c.status.value.en_GB !== 'REMOVED'
    )
    .map((c: TCompany): TLabelValue => ({
      label: c.name,
      value: c.id
    }))
    .sort((a, b) => stringComparator(a.label, b.label));
}

type TWithLocale = {
  value: TLocaleSet;
};
type TWithName = {
  name: string;
};
