import { basicInitialState } from 'store/_utils/basicInitialState';
import { TFarmState } from './farm';

export const farmInitialState: TFarmState = {
  ...basicInitialState,
  farm: null,
  fields: {},
  isTouched: false,
  photos: []
};
