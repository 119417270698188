import { push } from 'connected-react-router';

import { ENDPOINTS, ROUTES } from '../../other/config';
import { failAction, fetchAction, isAdmin, successAction } from '../_utils/helpers';
import { http } from '../../services/api';
import store from '../store';

import { ESessionActions } from './sessionConstants';
import { TAction } from '../_utils/types';
import { TSessionModel, TUser } from './session';
import { TStore } from '../storeModel';


/**
 * Checks whether a user session is still valid.
 */
export function sessionCheck() {
	return (dispatch) => {
		dispatch(fetchAction(ESessionActions.SESSION_CHECK_REQUEST));
		dispatch(storeRedirectPathAction());

		http(ENDPOINTS.SESSION, {}, { isAdmin: false, isRaw: true })
			.then(handleSessionResponse)
			.catch((e: Error) =>
				dispatch(failAction(ESessionActions.SESSION_CHECK_FAIL, e.message))
			);
	};
}


/**
 * Assigns user data.
 * @param user
 */
export function sessionSetUser(user?: TUser): TAction<
  TSessionModel,
  ESessionActions
> {
	return {
		type: ESessionActions.SESSION_SET_USER,
		payload: {
		  user: user || null
		}
	};
}


async function handleSessionResponse(resp: Response): Promise<void> {
	const { data: user } = await resp.json();

	if (resp.status === 401 || !isAdmin(user)) {
		return store.dispatch(
		  successAction(ESessionActions.SESSION_CHECK_UNAUTH, { user: null })
    );
	}

	store.dispatch(successAction(ESessionActions.SESSION_CHECK_SUCCESS, { user }));
	store.dispatch(followRedirectPathAction());
}

/** Stores given routing path. */
export const storeRedirectPathAction = (): TAction<
  TSessionModel,
  ESessionActions
> => ({
  type: ESessionActions.STORE_REDIRECT_PATH,
  payload: {
    redirectPath: window.location.pathname
  }
});

export function followRedirectPathAction() {
  return (dispatch, getState) => {
    const { session } = getState() as TStore;

    dispatch(push(session.redirectPath || ROUTES.HOME));

    dispatch({
      type: ESessionActions.CLEAR_REDIRECT_PATH,
      payload: { redirectPath: null }
    });
  };
}
