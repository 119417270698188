import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './Main.scss';
import Aside from './Aside';
import { companiesFetch } from '../../store/companies/companiesActions';
import Header from './Header';
import { logoutRequest } from '../../store/login/loginActions';
import { prefetchRequest } from '../../store/dictionaries/prefetchActions';
import { routing } from '../../other/routing';


class Main extends React.Component {
  state = { isVisible: false };

  componentDidMount() {
    this.props.prefetchData();
    this.props.fetchCompanies();
  }

  toggleVisibility = () => this.setState({ isVisible: !this.state.isVisible });

  render() {
    const { logout } = this.props;
    const { isVisible } = this.state;

    return (
      <div className="Main">
        <Aside isVisible={isVisible} onCollapse={this.toggleVisibility} />

        <div className="Main__content">
          <Header
            isVisible={isVisible}
						logout={logout}
						onCollapse={this.toggleVisibility}
          />

          <main className="Main__main">{routing}</main>
        </div>
      </div>
    );
  }

}


const mapDispatchToProps = (dispatch) => ({
	fetchCompanies: () => dispatch(companiesFetch()),
  logout: () => dispatch(logoutRequest()),
	prefetchData: () => dispatch(prefetchRequest()),
});

Main.propTypes = {
	fetchCompanies: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
	prefetchData: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(Main);
