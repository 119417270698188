import { readData, writeData } from './storage';

enum EAuthKeys {
  FF_ADMIN_AUTH = 'FF_ADMIN_AUTH',
  KEEP_LOGGED = 'KEEP_LOGGED',
  TOKEN = 'TOKEN'
}

/**
 * Sets keep logged flag.
 * @param keepLogged
 */
export function saveIfKeepLogged(keepLogged: boolean): void {
  writeAuthSettings({ [EAuthKeys.KEEP_LOGGED]: keepLogged });
}
export function shouldKeepLogged(): boolean {
  return readAuthSettings()[EAuthKeys.KEEP_LOGGED] === true;
}

export function saveToken(token?: string): void {
  writeAuthSettings({ [EAuthKeys.TOKEN]: token });
}
export function getToken(): string {
  return readAuthSettings()[EAuthKeys.TOKEN];
}

/**
 * A couple of basic methods.
 */
export function readAuthSettings(): any {
  return readData(EAuthKeys.FF_ADMIN_AUTH) || {};
}
export function writeAuthSettings(data: {[key: string]: any}): any {
  const current = readAuthSettings();
  return writeData(EAuthKeys.FF_ADMIN_AUTH, { ...current, ...data });
}
