import React from 'react';
import PropTypes from 'prop-types';
import Spin from 'antd/lib/spin';
import './Loading.scss';


export const Loading = ({ className }) => {
	const cssClass = className ? `Loading ${className}` : 'Loading';
	return (
		<div className={cssClass}>
			<Spin size="large" />
		</div>
	);
};

Loading.propTypes = {
	className: PropTypes.string
};
