import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { namespaces2reducers } from './_utils/reducerCreator';

const namespaces = [
  // General section
  'dictionaries',
  'login',
  'session',
  'search',
  'ui',
  // Company
  'companies',
  'company',
  // Farm,
  'farm',
  'farmLicence',
  'farmLicences',
  'farms',
  // People
  'people',
  'person',
  // Users
  'userEditor',
  'userGroupEditor',
  'userGroups',
  'users',
  // Vessel
  'owners',
  'reports',
  'vessel',
  'vessels'
];

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    ...namespaces2reducers(namespaces)
  });

export default createRootReducer;
