import { createAsyncActions } from '../_utils/createAsyncActions';
import { TSearchState } from './search';

export enum ESearchActions {
  SEARCH_TYPED = 'search/SEARCH_TYPED',
  SEARCH_CLEAR = 'search/SEARCH_CLEAR',
  SEARCH = 'search/SEARCH'
}

export const fetchActs = createAsyncActions<TSearchState>(ESearchActions.SEARCH);

